<template>
  <component :is="paymentComponent"
             ref="paymentComponent"
             @payment:created="(data) => emitFromChild(data,'created')"
             @payment:canceled="(data) => emitFromChild(data,'canceled')"
             @payment:complete="(data) => emitFromChild(data,'complete')"
             @payment:completed="(data) => emitFromChild(data,'completed')"
             @payment:failed="(data) => emitFromChild(data,'failed')"
             v-bind="$props">
    <template #before-ui>
      <slot name="before-ui"></slot>
    </template>
    <template #after-ui>
      <slot name="after-ui"></slot>
    </template>
  </component>
</template>

<script>
import asyncComponentFactory from "@/client/extensions/modules/asyncComponentFactory.js";
export default {
  components: {
    // paymentComponent: asyncComponentFactory(`components/payment/templates/${this.gateway}.vue`, true),
  },
  props: {
    gateway: {
      type: String,
      default: "offline",
    },
    amount: {
      type: Number,
      default: 0,
    },
    currency: {
      type: String,
      default: config.ecommerce.currency.default,
    },
  },
  data: function () {
    let safeGatewayName = utilities.ucFirst(this.gateway);
    return {
      paymentComponent: asyncComponentFactory(
        `components/payment/templates/${safeGatewayName}.vue`,
        true
      ),
    };
  },
  emits: ["payment:created", "payment:complete","payment:completed", "payment:failed"],
  computed: {
    safeAmount() {
      return parseInt(this.amount);
    },
    safeGatewayName() {
      return utilities.ucFirst(this.gateway);
    },
  },
  watch: {
    gateway(newVal) {
      let safeGatewayName = utilities.ucFirst(newVal);
      this.paymentComponent = asyncComponentFactory(
        `components/payment/templates/${safeGatewayName}.vue`,
        true
      );
    },
  },
  methods: {
    emitFromChild(eventData, eventName) {
      this.$emit(`payment:${eventName}`, eventData);
    },
    async executePayment(amount, currency) {
      if (!amount) {
        amount = this.safeAmount;
      }
      if (!currency) {
        currency = this.currency;
      }

      try {
        await this.$refs["paymentComponent"].executePayment(amount, currency);
      } catch (e) {
        this.$emit("payment:failed", {
          component: this.$refs["paymentComponent"],
          context: { message: "failed to dalegate to sub component" },
        });
      }
    },
  },
};
</script>

<style scoped lang="scss"></style>
